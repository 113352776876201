<template>
<main id="main" class="site-main">
    <mainbanner></mainbanner>
    <toprated></toprated>

    <section class="excellence">
        <div class="container">
            <h2 class="excellence__title">The <span>best packages</span> for the most popular destinations</h2>
            <div class="excellence__content" v-for="(about,index) in aboutblock" :key="index">
                <p>{{about.excerpt}}</p>
            </div>
            <div class="excellence__box">
                <h3 class="excellence__box-entitle">Service Excellence is our Most Important Value</h3>
                <ul class="excellence__box-list">
                    <li><img src="../assets/uploads/img08.jpg" alt="image description"></li>
                    <li><img src="../assets/uploads/img09.jpg" alt="image description"></li>
                    <li><img src="../assets/uploads/img10.jpg" alt="image description"></li>
                    <li><img src="../assets/uploads/img11.jpg" alt="image description"></li>
                </ul>
            </div>
            <div class="row">
                <div class="col xs-12 sm-6 md-3">
                    <div class="excellence__item">
                        <div class="svg-wrap"><svg>
                                <use xlink:href="#svg-trekker-line"></use>
                            </svg></div>
                        <h3 class="excellence__item-title">Trekking</h3>
                        <p> Treks in Nepal can be distinguished in terms of duration, mode of accommodation, and difficulty among various other factors...</p>
                        <!-- <a href="category.html" class="excellence__item-more">Know More</a> -->
                        <router-link :to="'/category/trekking'" class="excellence__item-more">Know More</router-link>
                    </div>
                </div>
                <div class="col xs-12 sm-6 md-3">
                    <div class="excellence__item">
                        <div class="svg-wrap"><svg>
                                <use xlink:href="#svg-mountain"></use>
                            </svg></div>
                        <h3 class="excellence__item-title">Mountain Climbing</h3>
                        <p> Whether you only seek to practice climbing or want to try the hardest of the challenges, you will find the right peak for you here in Nepal...</p>
                        <router-link :to="'/category/peak-climbing'" class="excellence__item-more">Know More</router-link>
                    </div>
                </div>
                <div class="col xs-12 sm-6 md-3">
                    <div class="excellence__item">
                        <div class="svg-wrap"><svg>
                                <use xlink:href="#svg-rafting"></use>
                            </svg></div>
                        <h3 class="excellence__item-title">Adventure Activities</h3>
                        <p> Without losing the comfort of the city, climbing high and walking long to see Nepalese rural life...</p>
                        <router-link :to="'/category/hiking'" class="excellence__item-more">Know More</router-link>
                    </div>
                </div>
                <div class="col xs-12 sm-6 md-3">
                    <div class="excellence__item">
                        <div class="svg-wrap"><svg>
                                <use xlink:href="#svg-slipper"></use>
                            </svg></div>
                        <h3 class="excellence__item-title">Day Trips</h3>
                        <p> For over forty years, Nepal has been an extremely fascinating destination for tourists, trekker, river runner, wildlife enthusiast...</p>
                        <router-link :to="'/category/tours'" class="excellence__item-more">Know More</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <combopackage></combopackage>
    <featured></featured>
    <chooseus></chooseus>
    <section class="bestpick" v-for="(seasontrip,index) in seasonTrips" :key="index">
        <img :src="urlPath+'uploads/media/package/'+seasontrip.featuredimage" alt="{{seasontrip.title}}" class="bestpick__img">
        <div class="container">
            <div class="bestpick__inscript">
                <span class="bestpick__inscript-entitle">Trip of the Season<span class="price" v-if="seasontrip.discountedprice">US$ {{seasontrip.discountedprice}}</span><span class="price" v-else>US$ {{seasontrip.regularprice}}</span></span>
                <h2 class="bestpick__inscript-title">{{seasontrip.title}}</h2>
                <ul class="bestpick__inscript-list">
                    <li><span class="icon-time"></span><span class="text">{{ seasontrip.tripdays }} Days</span></li>
                    <li><span class="icon-star-line"></span><span class="text">{{seasontrip.packagerating}} Rating</span></li>
                </ul>
                <router-link :to="'/package/'+seasontrip.slug" class="btn btn-primary">Explore Package</router-link>
            </div>
        </div>
    </section>
    <testimonials></testimonials>
    <blog></blog>
</main>
</template>

<script>
import axios from "axios";
import jQuery from 'jquery';
import "../assets/js/jquery.slick.min.js";
import "../assets/js/jquery.matchHeight.js";
// import "../assets/js/svg-sprites.js";
import { defineAsyncComponent } from "@vue/runtime-core";

const Mainbanner = defineAsyncComponent(() => import(/* webpackChunkName: "Mainbanner" */ './Homecomponent/Mainbanner.vue'));
const Toprated = defineAsyncComponent(() => import(/* webpackChunkName: "Toprated" */ './Homecomponent/Toprated.vue'));
const Combopackage = defineAsyncComponent(() => import(/* webpackChunkName: "Combopackage" */ './Homecomponent/Combopackage.vue'));
const Awesomepackage = defineAsyncComponent(() => import(/* webpackChunkName: "Awesomepackage" */ './Homecomponent/Awesomepackage.vue'));
const Chooseus = defineAsyncComponent(() => import(/* webpackChunkName: "Chooseus" */ './Homecomponent/Chooseus.vue'));
const Testimonials = defineAsyncComponent(() => import(/* webpackChunkName: "Testimonials" */ './Homecomponent/Testimonials.vue'));
const Latestpost = defineAsyncComponent(() => import(/* webpackChunkName: "Latestpost" */ './Homecomponent/Blogsection.vue'));

export default ({
    name: 'Home',
    data() {
        return {
            aboutblock: undefined,
            seasonTrips: undefined,
            apiPath:process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,
        }
    },
    async mounted() {
        this.aboutPage();
        this.seasonPackage();
        jQuery("body").prepend(window.SVG_SPRITES.sprite);
        
        
    },
    methods: {
        aboutPage() {
            axios.get(this.apiPath+'showpage/about-us')
                .then((response) => {
                    this.aboutblock = response.data;
                    const descEl = document.querySelector('head meta[name="description"]');
                    const keyEl = document.querySelector('head meta[name="keywords"]');
                    const titleEl = document.querySelector('head title');

                    descEl.setAttribute('content', 'Nepal Alternative Treks SEO description content comes here.');
                    keyEl.setAttribute('content', 'Nepal Alternative Treks SEO keywords content comes here.');
                    titleEl.textContent = "Nepal Alternative Treks";
                })

        },
        
        seasonPackage() {
            axios.get(this.apiPath+'seasontrip')
                .then((response) => {
                    this.seasonTrips = response.data;
                })
        },
        
    },
    components: {
        'mainbanner': Mainbanner,
        'toprated': Toprated,
        'combopackage': Combopackage,
        'featured': Awesomepackage,
        'chooseus': Chooseus,
        'testimonials': Testimonials,
        'blog': Latestpost,
    }
})

</script>
